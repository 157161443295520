<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
        >
          <p
            class="mb-0 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
            :class="$vuetify.breakpoint.xsOnly ? 'font-30' : 'font-40'"
          >
            My Account
          </p>
        </v-card>
      </v-card>
      <v-tabs v-model="tab" class="account-tabs" centered>
        <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
        <template>
          <template v-for="(item, index) in tabs">
            <v-tab
              :ripple="false"
              class="text-capitalize font-14 line-h-22 letter-s-0"
              :href="`#${item.slug}`"
              :key="index"
            >
              {{ item.name }}
            </v-tab>
          </template>
        </template>
      </v-tabs>
      <v-divider class="line"></v-divider>
      <v-container
        :class="$vuetify.breakpoint.xsOnly ? 'pa-8' : 'pt-52 pb-207'"
      >
        <v-card flat color="transparent" width="760" class="border-r-0 ma-auto">
          <v-tabs-items v-model="tab">
            <v-tab-item value="ticket-orders">
              <tickets-orders :orders="orders"></tickets-orders>

              <div
                class="mt-47 font-20 line-h-31 font-weight--regular text-color-secondary-300"
              >
                If you have any questions or queries about your order, then
                please
                <a
                  href="/contact-us"
                  class="text-decoration-underline font-weight--extra-bold text-color-secondary-300"
                  >contact us</a
                >
                or call
                <a
                  href="tel:0871 284 5277"
                  class="text-decoration-underline font-weight--extra-bold text-color-secondary-300"
                  >0871 284 5277</a
                >
                with your order number and we will be happy to help. For more
                information, please visit our
                <a
                  href="/faqs"
                  class="text-decoration-underline font-weight--extra-bold text-color-secondary-300"
                  >FAQs</a
                >
                and read our
                <a
                  href="/terms-and-conditions"
                  class="text-decoration-underline font-weight--extra-bold text-color-secondary-300"
                  >Disclaimer.</a
                >
              </div>
            </v-tab-item>
            <v-tab-item value="profile-info">
              <profile :orders="orders.user"></profile>
            </v-tab-item>
            <v-tab-item @click="logout" value="logout">
              <v-btn @click="logout">Click here to Logout</v-btn>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-container>
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheFooter from "../components/TheFooter.vue";
import TheHeader from "../components/TheHeader.vue";
import TicketsOrders from "@/components/AccountMolecules/TicketsOrders.vue";
import Profile from "@/components/AccountMolecules/Profile.vue";

export default {
  name: "Account",
  components: { TheHeader, TheFooter, TicketsOrders, Profile },
  data: () => ({
    tab: "ticket-orders",
    tabs: [
      {
        name: "Ticket Orders",
        slug: "ticket-orders",
      },
      {
        name: "Profile",
        slug: "profile-info",
      },
      {
        name: "Log Out",
        slug: "logout",
      },
    ],
  }),
  mounted() {
    this.getOrders();
  },
  computed: {
    orders() {
      return this.$store.state.orders.loggedInUsersOrders;
    },
  },
  methods: {
    async getOrders() {
      await this.$store.dispatch("orders/RETRIEVE_LOGGEDIN_USERS_ORDERS");
    },
    async logout() {
      await this.$store.dispatch("auth/LOGOUT");
    },
  },
};
</script>
<style></style>
