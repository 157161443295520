<template>
  <div>
    <div
      v-if="orders.length < 1"
      class="px-63 py-43 bg-color-grey-200 d-flex align-center justify-space-between mb-8"
    >
      <img src="@/assets/images/cross.png" />
      <p
        class="font-18 line-h-25 font-weight--bold text-color-secondary-100 letter-s-25 mb-0"
      >
        Looks like you don’t have any previous tickets ordered.
      </p>
    </div>
    <v-card class="ftl__expansion__row" elevation="0">
      <v-expansion-panels class="ftl__expansion" flat>
        <v-expansion-panel
          expand
          class="ftl__expansion__panel mt-4"
          v-for="(order, i) in orders"
          :key="i"
          active-class="expanded-panel"
        >
          <v-expansion-panel-header
            class="d-flex flex-column align-start ticket-header"
          >
            <template v-slot:actions>
              <img src="@/assets/images/chevron-down.svg" />
            </template>
            <div class="w-100 mb-17">
              <p
                class="mb-0 font-24 line-h-37 font-weight--regular text-color-secondary-300"
              >
                {{ order.fixture_date | moment("dddd MMMM Do YYYY") }} |
                Kickoff:
                <span class="font-weight--extra-bold">
                  {{ order.tournament_id }}
                </span>
              </p>
              <p
                class="mb-0 font-24 line-h-37 font-weight--bold text-color-secondary-100"
              >
                {{ order.team1_name | capitalize }} vs
                {{ order.team2_name | capitalize }}
              </p>
            </div>
            <div
              class="w-100 mb-18 d-flex align-center justify-space-between font-16 line-h-25 font-weight--bold text-color-secondary-100"
            >
              <p v-if="order.fixture_category_price">
                {{
                  order.fixture_category_price.stadium_category.label
                    | capitalize
                }}
              </p>
              <p>x{{ order.quantity }} tickets</p>
            </div>
            <v-divider class="w-100" color="#ccc"></v-divider>
            <div
              class="font-16 line-h-25 font-weight--regular text-color-secondary-300 w-100 mt-2 d-flex align-center justify-space-between"
            >
              <div
                class="d-flex font-16 line-h-25 font-weight--regular text-color-secondary-300"
              >
                <span v-if="order.stadium_name">
                  {{ order.stadium_name | capitalize }}
                </span>
                <span v-if="order.stadium_city_name">
                  ,&nbsp; {{ order.stadium_city_name | capitalize }}
                </span>
                <span v-if="order.stadium_country_name">
                  ,&nbsp;{{ order.stadium_country_name | capitalize }}
                </span>
                <img src="@/assets/images/uk-flag.svg" class="ml-2" />
              </div>
              <div class="d-flex">
                <img
                  :src="order.tournament_image"
                  class="mr-2"
                  width="35"
                  height="35"
                />
                {{ order.tournament_name }}
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#F2F2F3">
            <div>
              <p
                class="font-20 line-h-31 font-weight--bold text-color-secondary-100 text-decoration-underline"
              >
                Your details:
              </p>
              <p
                v-if="order.user.first_name"
                class="font-20 line-h-31 font-weight--regular text-color-secondary-300 mb-2"
              >
                {{ order.user.first_name | capitalize }}
                {{ order.user.last_name | capitalize }}
              </p>
              <p
                v-if="order.user.email"
                class="font-20 line-h-31 font-weight--regular text-color-secondary-300 mb-2"
              >
                {{ order.user.email }}
              </p>
              <p
                v-if="order.user.phone"
                class="font-20 line-h-31 font-weight--regular text-color-secondary-300 mb-2"
              >
                {{ order.user.phone }}
              </p>
            </div>
            <v-divider class="w-100 mt-35 mb-27" color="#ccc"></v-divider>
            <div v-if="order.shipping_address">
              <p
                class="font-20 line-h-31 font-weight--bold text-color-secondary-100 text-decoration-underline"
              >
                Ticket delivery:
              </p>
              <p
                v-if="order.shipping_address.address_line_one"
                class="font-20 line-h-31 font-weight--regular text-color-secondary-300 mb-2"
              >
                {{ order.shipping_address.address_line_one | capitalize }}
              </p>
              <p
                v-if="order.shipping_address.city_name"
                class="font-20 line-h-31 font-weight--regular text-color-secondary-300 mb-2"
              >
                {{ order.shipping_address.city_name | capitalize }}
              </p>
              <p
                v-if="order.shipping_address.country_name"
                class="font-20 line-h-31 font-weight--regular text-color-secondary-300 mb-2"
              >
                {{ order.shipping_address.country_name | capitalize }} -
                {{ order.shipping_address.postcode.toUpperCase() }}
              </p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "UsersTicketsOrders",

  data() {
    return {};
  },
  props: {
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    watchExpand() {
      this.isExpan;
    },
  },
};
</script>
<style lang="css" scoped></style>
