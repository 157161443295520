<template>
  <div>
    <p class="font-20 line-h-31 font-weight--bold text-color-secondary-100">
      Your Details:
    </p>
    <v-form ref="form">
      <template v-if="!showAddressForm">
        <v-row dense class="e-row">
          <v-col cols="6" md="2" class="e-col">
            <div>
              <label>
                <span
                  class="font-16 line-h-16 text-color-secondary-100 font-weight--bold"
                  >Title</span
                >
              </label>
              <v-select
                v-model="users.title"
                autocomplete="off"
                :items="titles"
                solo
                outlined
                flat
                class="solo-cust border-r-0"
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon>mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="6" md="5" class="e-col">
            <div>
              <label>
                <span
                  class="font-16 line-h-16 text-color-secondary-100 font-weight--bold"
                >
                  First Name
                </span>
              </label>
              <v-text-field
                v-model="users.first_name"
                :rules="rules.name"
                autocomplete="off"
                solo
                outlined
                flat
                hide-details="auto"
                class="solo-cust border-r-0"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="6" md="5" class="e-col">
            <div>
              <label>
                <span
                  class="font-16 line-h-16 text-color-secondary-100 font-weight--bold"
                >
                  Last Name
                </span>
              </label>
              <v-text-field
                v-model="users.last_name"
                :rules="rules.name"
                autocomplete="off"
                solo
                outlined
                flat
                hide-details="auto"
                class="solo-cust border-r-0"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row dense class="e-row mt-6">
          <v-col cols="12" md="12" class="e-col">
            <div>
              <label>
                <span
                  class="font-16 line-h-16 text-color-secondary-100 font-weight--bold"
                >
                  Email Address
                </span>
              </label>
              <v-text-field
                v-model="users.email"
                :rules="rules.email"
                type="email"
                autocomplete="off"
                solo
                outlined
                flat
                hide-details="auto"
                class="solo-cust border-r-0"
                disabled
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row dense class="e-row mt-6">
          <v-col cols="12" md="6" class="e-col">
            <div>
              <label>
                <span
                  class="font-16 line-h-16 text-color-secondary-100 font-weight--bold"
                >
                  Home Number
                </span>
              </label>
              <v-text-field
                v-model="users.phone"
                :rules="rules.phone"
                autocomplete="off"
                solo
                outlined
                flat
                hide-details="auto"
                class="solo-cust border-r-0"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="e-col">
            <div>
              <label>
                <span
                  class="font-16 line-h-16 text-color-secondary-100 font-weight--bold"
                >
                  Mobile Number
                </span>
              </label>
              <v-text-field
                v-model="users.mobile"
                autocomplete="off"
                solo
                outlined
                flat
                hide-details="auto"
                class="solo-cust border-r-0"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="addresses" dense class="e-row mt-6">
          <v-col cols="12" md="4" class="e-col">
            <div>
              <label>
                <span
                  class="font-16 line-h-16 text-color-secondary-100 font-weight--bold"
                >
                  Saved Address
                </span>
              </label>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="addresses" dense class="e-row mt-2">
          <v-col
            v-for="address in addresses"
            :key="address.id"
            cols="12"
            md="6"
            class="e-col"
          >
            <div>
              <div class="address-input mb-2">
                <v-chip
                  label
                  class="float-right font-14 line-h-14 text-color-secondary-100 font-weight--bold"
                >
                  {{ getAddressType(address.address_type) }}
                </v-chip>
                <v-btn
                  label
                  outlined
                  small
                  class="float-right font-14 line-h-14 text-color-secondary-100 font-weight--bold text-capitalize mr-2"
                  @click="
                    action = 'edit';
                    setAddress(address);
                  "
                >
                  Edit
                </v-btn>
                <p v-if="address.address_line_one" class="mb-0 add-info">
                  {{ address.address_line_one | capitalize }}
                </p>
                <p v-if="address.address_line_two" class="mb-0 add-info">
                  {{ address.address_line_two | capitalize }}
                </p>
                <p v-if="address.city_name" class="mb-0 add-info">
                  {{ address.city_name | capitalize }}
                </p>
                <p v-if="address.postcode" class="mb-0 add-info">
                  {{ address.postcode | uppercase }}
                </p>
                <p v-if="address.country_name" class="mb-0 add-info">
                  {{ address.country_name | capitalize }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <div v-if="addressesPagination" class="text-center">
          <v-pagination
            v-model="addressesPagination.current_page"
            :length="addressesPagination.last_page"
            @input="paginate"
            circle
          ></v-pagination>
        </div>
      </template>
      <v-row class="e-row mt-1">
        <v-col cols="12" sm="12">
          <v-btn
            :ripple="false"
            outlined
            solo
            depressed
            class="btn-address"
            @click="
              action = 'create';
              setAddress(addressSelected);
            "
          >
            {{ !showAddressForm ? "+ Add" : "- Close" }} Address
          </v-btn>
        </v-col>
      </v-row>
      <div class="d-flex justify-end mt-6">
        <v-btn
          v-if="!showAddressForm"
          :ripple="false"
          color="transparent"
          class="sell-your-ticket-submit height-59 width-166 btn-primary text-capitalize font-20 line-h-20 font-weight--bold letter-s-0 text-color-secondary-100 border-r-0"
          elevation="0"
          @click="saveUser"
        >
          Save
          <img class="ml-2 mt-1" src="@/assets/images/right-arrow.svg" alt="" />
        </v-btn>
      </div>
    </v-form>
    <ProfileAddress
      v-if="showAddressForm"
      :address="addressSelected"
      :action="action"
      @done="setAddress"
    />
  </div>
</template>
<script>
import ProfileAddress from "@/components/AccountMolecules/ProfileAddress";
export default {
  name: "Profile",
  data() {
    return {
      titles: ["Mr", "Mrs", "Dr", "Miss"],
      rules: {
        required: [(v) => !!v || "Please enter valid data"],
        name: [
          (v) => !!v || "Please enter name",
          (v) => (v && v.length >= 2) || "Name should be more than 2 character",
        ],
        phone: [
          (v) => !!v || "Please enter valid phone",
          (v) =>
            (v && v.length >= 10 && v.length <= 11) ||
            "Phone number must have 10  to 11 digits",
          (v) =>
            (v && v != 9876543210 && v != 98765432100 && v != 0) ||
            "Phone number must a valid number",
        ],
        postcode: [
          (v) => !!v || "Please enter postcode",
          (v) =>
            (v && v.length >= 5 && v.length <= 7) ||
            "Postcode must have 5 to 7 characters",
        ],
        email: [
          (v) => !!v || "Please enter an Email",
          (v) =>
            // eslint-disable-next-line no-useless-escape
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ],
        passwordRules: [
          (v) => !!v || "Password is required",
          (v) => (v && v.length >= 5) || "Password must have 5+ characters",
          (v) => /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
          (v) => /(?=.*\d)/.test(v) || "Must have one number",
          (v) =>
            /([!@$%])/.test(v) || "Must have one special character [!@#$%]",
        ],
      },
      action: null,
      showAddressForm: false,
      addressSelected: {
        address_type: null,
      },
    };
  },
  components: {
    ProfileAddress,
  },
  computed: {
    users() {
      return JSON.parse(localStorage.getItem("user"));
    },
    addresses() {
      if (this.$store.state.users.usersAddress) {
        return this.$store.state.users.usersAddress.data;
      } else return [];
    },
    addressesPagination() {
      if (this.$store.state.users.usersAddress) {
        return this.$store.state.users.usersAddressPagination;
      } else return [];
    },
  },
  mounted() {
    this.$store.dispatch("users/GET_LOGGEDIN_USER_ADDRESS", { per_page: 4 });
  },
  methods: {
    setAddress(address) {
      if (!this.showAddressForm) {
        this.addressSelected = address;
        this.showAddressForm = true;
      } else {
        this.addressSelected = {
          address_type: null,
        };
        this.showAddressForm = false;
      }
    },
    async saveUser() {
      await this.$store.dispatch("users/UPDATE_USER", this.users);
      await this.$store.dispatch("auth/GET_LOGGEDIN_USER_INFO");
      await this.$store.dispatch("users/GET_LOGGEDIN_USER_ADDRESS", {
        per_page: 4,
      });
    },
    getAddressType(type) {
      switch (type) {
        case "1":
          return "Home";
        case "2":
          return "Office";
        case "3":
          return "Hotel";
        case "4":
          return "Hostel";
        case "5":
          return "Apartment";
        default:
          return typeof type;
      }
    },
    async paginate(page) {
      await this.$store.dispatch("users/GET_LOGGEDIN_USER_ADDRESS", {
        page,
        per_page: this.addressesPagination.per_page,
      });
    },
  },
};
</script>
<style lang="css" scoped></style>
