<template>
  <v-form ref="form">
    <div class="country-sec">
      <div class="add-type">
        <v-row>
          <v-col cols="12" sm="12">
            <v-select
              v-model="addressType"
              :rules="[
                (v) => !!v || 'You must select shipping address to continue!',
              ]"
              hide-details="auto"
              :items="addressTypeList"
              placeholder="-- Select address type --"
              item-text="name"
              item-value="id"
              solo
              flat
              outlined
              class="cart-input"
              @change="resetForm"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <div class="sec-details">
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              v-model="addressForm.first_name"
              :rules="rules.name"
              hide-details="auto"
              placeholder="First Name*"
              outlined
              solo
              flat
              class="cart-input"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              v-model="addressForm.last_name"
              :rules="rules.name"
              hide-details="auto"
              placeholder="Last Name*"
              outlined
              solo
              flat
              class="cart-input"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="sec-details">
        <v-row v-if="addressType == 2">
          <v-col cols="12" md="12" sm="12">
            <v-text-field
              v-model="addressForm.company_name"
              :rules="[
                (v) => !!v || 'You must enter company name to continue!',
              ]"
              hide-details="auto"
              placeholder="Company Name*"
              outlined
              solo
              flat
              class="cart2-input"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                  >mdil-office</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field
              v-model="addressForm.company_start_time"
              :rules="[(v) => !!v || 'You must enter start time to continue!']"
              hide-details="auto"
              placeholder="Company Start Time*"
              outlined
              solo
              flat
              class="cart2-input"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                  >mdil-clock</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field
              v-model="addressForm.company_end_time"
              hide-details="auto"
              placeholder="Company End Time"
              outlined
              solo
              flat
              class="cart2-input"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                  >mdil-clock</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="sec-details">
        <v-row v-if="addressType > 2">
          <v-col cols="12" md="12" sm="12">
            <v-text-field
              v-model="addressForm.boarding_name"
              :rules="[(v) => !!v || 'You must enter name to continue!']"
              hide-details="auto"
              :placeholder="`${
                addressType == 3
                  ? 'Hotel'
                  : addressType == 4
                  ? 'Hostel'
                  : 'Apartment'
              } Name*`"
              outlined
              solo
              flat
              class="cart-input"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                  >mdil-domain</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="addressType == 3">
          <v-col cols="6" md="6" sm="12">
            <v-menu
              ref="checkInMenu"
              v-model="checkInMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="cart2-input"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateUkFormat(addressForm.checkin_time)"
                  :rules="[
                    (v) => !!v || 'You must enter start time to continue!',
                  ]"
                  hide-details="auto"
                  solo
                  outlined
                  flat
                  v-bind="attrs"
                  v-on="on"
                  placeholder="Check-out date"
                  class="cart2-input"
                >
                  <template slot="prepend-inner">
                    <img
                      class="mr-3 ml-5 mt-1 calendar"
                      src="../../assets/images/calendar.svg"
                    />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="addressForm.checkin_time"
                no-title
                scrollable
                class="cart2-input"
              >
                <v-spacer></v-spacer>
                <v-btn
                  :ripple="false"
                  text
                  color="primary"
                  @click="checkInMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  :ripple="false"
                  color="primary"
                  @click="$refs.checkInMenu.save(addressForm.checkin_time)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <!-- <v-text-field
                  v-model="addressForm.checkin_time"
                  :rules="[
                    (v) => !!v || 'You must enter start time to continue!',
                  ]"
                  hide-details="auto"
                  placeholder="Check-in Date*"
                  outlined
                  solo
                  flat
                  class="cart2-input"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                      >mdil-calendar</v-icon
                    >
                  </template>
                </v-text-field> -->
          </v-col>
          <v-col cols="6" md="6" sm="12">
            <v-menu
              ref="checkOutMenu"
              v-model="checkOutMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="cart2-input"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateUkFormat(addressForm.checkout_time)"
                  hide-details="auto"
                  solo
                  outlined
                  flat
                  v-bind="attrs"
                  v-on="on"
                  placeholder="Check-out date"
                  class="cart2-input"
                >
                  <template slot="prepend-inner">
                    <img
                      class="mr-3 ml-5 mt-1 calendar"
                      src="../../assets/images/calendar.svg"
                    />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="addressForm.checkout_time"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  :ripple="false"
                  text
                  color="primary"
                  @click="checkOutMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  :ripple="false"
                  color="primary"
                  @click="$refs.checkOutMenu.save(addressForm.checkout_time)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <!-- <v-text-field
                  v-model="addressForm.checkout_time"
                  hide-details="auto"
                  placeholder="Check-out date"
                  outlined
                  solo
                  flat
                  class="cart2-input"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                      >mdil-calendar</v-icon
                    >
                  </template>
                </v-text-field> -->
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-text-field
              v-model="addressForm.reservation_name"
              hide-details="auto"
              placeholder="Reservation Name"
              outlined
              solo
              flat
              class="cart2-input"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                  >mdil-account</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="sec-details">
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="addressForm.address_line_one"
              :rules="[
                (v) => !!v || 'You must enter valid address to continue!',
              ]"
              hide-details="auto"
              placeholder="Address"
              outlined
              solo
              flat
              class="cart2-input"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                  >mdil-map-marker</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="addressForm.address_line_two"
              hide-details="auto"
              placeholder="Address line two"
              outlined
              solo
              flat
              class="cart2-input"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                  >mdil-map-marker</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="addressForm.city_name"
              :rules="[(v) => !!v || 'You must enter city name to continue!']"
              hide-details="auto"
              placeholder="City / Town*"
              outlined
              solo
              flat
              class="cart-input"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="addressForm.county"
              hide-details="auto"
              placeholder="State/County"
              outlined
              solo
              flat
              class="cart-input"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              :rules="[(v) => !!v || 'You must select country to continue!']"
              v-model="addressForm.country_id"
              hide-details="auto"
              :items="countryList"
              placeholder="Select Country*"
              item-text="name"
              solo
              flat
              outlined
              class="cart-input"
              return-object
              @change="selectedCountry"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="addressForm.postcode"
              :rules="[
                (v) => !!v || 'You must enter postcode or zip to continue!',
              ]"
              hide-details="auto"
              placeholder="Postcode / ZIP"
              outlined
              solo
              flat
              class="cart-input"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex justify-end mt-6">
        <v-btn
          :ripple="false"
          color="transparent"
          class="sell-your-ticket-submit height-65 width-200 btn-primary text-capitalize font-20 line-h-20 font-weight--bold letter-s-0 text-color-secondary-100 border-r-0"
          elevation="0"
          @click="saveAddress"
        >
          Save Address
          <img class="ml-2 mt-1" src="@/assets/images/right-arrow.svg" alt="" />
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import DateFormat from "../../mixins/Dates/ukFormat.js";
export default {
  name: "ProfileAddress",
  mixins: [DateFormat],
  data: () => ({
    rules: {
      required: [(v) => !!v || "Please enter valid data"],
      name: [
        (v) => !!v || "Please enter name",
        (v) => (v && v.length >= 2) || "Name should be more than 2 character",
      ],
      phone: [
        (v) => !!v || "Please enter valid phone",
        (v) =>
          (v && v.length >= 10 && v.length <= 11) ||
          "Phone number must have 10  to 11 digits",
        (v) =>
          (v && v != 9876543210 && v != 98765432100 && v != 0) ||
          "Phone number must a valid number",
      ],
      postcode: [
        (v) => !!v || "Please enter postcode",
        (v) =>
          (v && v.length >= 5 && v.length <= 7) ||
          "Postcode must have 5 to 7 characters",
      ],
      email: [
        (v) => !!v || "Please enter an Email",
        (v) =>
          // eslint-disable-next-line no-useless-escape
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 5) || "Password must have 5+ characters",
        (v) => /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Must have one number",
        (v) => /([!@$%])/.test(v) || "Must have one special character [!@#$%]",
      ],
    },
    checkOutMenu: false,
    checkInMenu: false,
    addressTypeList: [
      {
        name: "Home",
        id: "1",
      },
      {
        name: "Office",
        id: "2",
      },
      {
        name: "Hotel",
        id: "3",
      },
      {
        name: "Hostel",
        id: "4",
      },
      {
        name: "Apartment",
        id: "5",
      },
    ],
    countryList: [
      {
        name: "Germany",
        value: 2,
      },
      {
        name: "India",
        value: 6,
      },
      {
        name: "Spain",
        value: 3,
      },
      {
        name: "United Kingdom",
        value: 1,
      },
    ],
    addressForm: {},
    addressType: null,
  }),
  props: {
    address: {
      Type: Object,
      default() {
        return { address_type: null };
      },
    },
    action: {
      Type: String,
      default() {
        return null;
      },
    },
  },
  watch: {
    address: {
      handler: function () {},
      deep: true,
    },
  },
  mounted() {
    this.addressForm = this.address;
    this.addressType = this.address.address_type;
  },
  methods: {
    selectedCountry(item) {
      this.addressForm.country_name = item.name;
      this.addressForm.country_id = item.value;
    },
    resetForm(e) {
      this.addressForm = {};
      this.addressForm.address_type = e;
      if ("id" in this.address) this.addressForm.id = this.address.id;
    },
    async saveAddress() {
      if (!this.$refs.form.validate()) return;
      this.addressForm.address_type = this.addressType;
      if (this.action === "edit")
        await this.$store.dispatch(
          "users/UPDATE_USER_ADDRESS",
          this.addressForm
        );
      if (this.action === "create")
        await this.$store.dispatch(
          "users/CREATE_USER_ADDRESS",
          this.addressForm
        );
      this.$store.dispatch("users/GET_LOGGEDIN_USER_ADDRESS", { per_page: 2 });
      this.$emit("done", this.address);
    },
  },
};
</script>

<style></style>
